<template>
  <div>
     
        <div v-if="pgLoading" class="property-query-area clearfix webkit-animation webkit-100per-536"></div>
        <div v-if="!pgLoading" class="property-query-area clearfix">
          <div class="col-md-12">
            <h3 class="text-uppercase bottom20 top15"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                  {{ $t('app.contact_us') }}
            </h3>
          </div>
          
          <form @submit.prevent="sendMessage" class="callus padding pd25">
              <div class="form-group">
                  <input type="text" 
                         class="form-control"
                         :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                         :placeholder="$t('app.name')"
                         v-model="row.name">
              </div>
              <div class="form-group">
                    <!-- <input type="tel" 
                         class="form-control" 
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        :placeholder="$t('app.phone_number')"> -->
                    <vue-tel-input 
                        class="form-control"
                        :inputOptions="options"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''" 
                        v-model="row.phone_number">
                    </vue-tel-input>
              </div>
              <div class="form-group">
                  <input type="email" 
                         class="form-control" 
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        :placeholder="$t('app.email_address')"
                        v-model="row.email_address">
              </div>
              <div class="form-group">
                  <textarea 
                      class="form-control" 
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                      :placeholder="$t('app.message')"
                      v-model="row.message">
                  </textarea>
              </div>
              <button :disabled="btnLoading"
                    class="btn-blue uppercase border_radius"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                    style="width: 120px;height: 50px">
                  <span v-if="btnLoading">
                     <div class="loaderN loaderN-md mauto mt-15" style='margin-left:-15px'></div>
                  </span>
                  <span v-if="!btnLoading">{{ $t('app.submit_now') }}</span>
              </button>
          </form>
        </div>

  </div>
</template>

<script>
import iziToast from 'izitoast'
import { VueTelInput } from 'vue-tel-input'

export default {
    name: 'ContactForm',
    props: ['pgLoading'],
    components: {
        VueTelInput,
    },
    data(){
        return {
            btnLoading: false,
            options: {
                placeholder: (this.$i18n.locale == 'ar') ? 'رقم الهاتف' : 'Phone Number'
            }, 
            row: {
                name: '',
                email_address: '',
                phone_number: '',
                message: '',
            },
        }
    },
    mounted() {},
    created() {},
    methods: {

        //
        sendMessage() {
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/contacts',
                method: 'POST',
                data: {
                    referral: this.$route.path,
                    name: this.row.name,
                    email_address: this.row.email_address,
                    phone_number: this.row.phone_number,
                    message: this.row.message
                },
            }
            this.axios(options)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'ti-check',
                    title: 'Great job,',
                    message: 'Message Sent Successfully',
                });

                this.row.message = '';
            })
            .catch(err => {
               
                this.btnLoading = false;
                iziToast.warning({
                    icon: 'ti-alert',
                    title: 'Wow-man,',
                    message: (err.response) ? err.response.data.message : 'Something went wrong'
                });

            })
            .finally(() => {});
        },

    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
